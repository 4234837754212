import logo from './logo.svg';
import './App.css';
import background from "./img/background.webp";

function App() {
    return (
        <div className="App">

            <header className="App-header ">
                <p><img src={logo} className="App-logo" alt="logo"/></p>
            </header>

            <div className="infowrapper">
                <div className="contentwrapper">
                    <h1 className="contentelement">the road</h1>
                    <h1 className="contentelement">to great code</h1>
                </div>
                <div className="contentwrapper">
                    <div className="contentelement">
                        hit the code provides world class AEM consulting and software development.
                    </div>
                    <h1 className="contentelement ">Contact:</h1>
                    <div className="contentelement ">
                        <p><a href="mailto:info@hitthecode.de">email</a></p>
                        <p>hit the code gmbh</p>
                        <p>Blumenstraße 6</p>
                        <p>98693 Ilmenau</p>
                        <p>Germany</p>
                    </div>
                </div>
                <div className="contentwrapper credits">
                    <a href="https://flic.kr/p/nzjcLr">Background Photo</a> by <a
                    href="https://www.flickr.com/photos/97671235@N02/">YoniLL</a> / CC BY 2.0
                </div>
            </div>
            <div className="space">
            </div>
        </div>
);
}

export default App;
